@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700");

.main_container {
  padding: 40px 40px 0px 40px;
}
.search-grid {
  text-align: start;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  grid-column-gap: 1em;
  margin-bottom: 1em;
  @media (max-width: 839px) {
    grid-template-columns: 1fr;
  }
}
.file-grid {
  text-align: start;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  grid-column-gap: 1em;
  margin-bottom: 1em;
  @media (max-width: 839px) {
    grid-template-columns: 1fr;
  }
}

.active-btn {
  background-color: #00c7b1!important;
  color: #ffffff;
  font-weight: 500;
  border: 1px solid #00c7b1!important;
  padding: 10px 16px;
  line-height: 1.7 !important;
  &:hover {
    background-color: #00c7b1!important;
    color: #ffffff !important;
    border: 1px solid #00c7b1!important;
  }
  &:focus {
    background-color: #00c7b1!important;
    color: #ffffff !important;
    border: 1px solid #00c7b1!important;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
  }
  @media (max-width: 839px) {
    width: 100%;
  }
}
.active-btn1 {
  border: solid 2px #00c7b1 !important;
  color: #00c7b1 !important;
  background-color: #ffffff !important;
  font-weight: 500;
  border: 1px solid #00c7b1!important;
  padding: 10px 16px;
  line-height: 1.7 !important;
  &:hover {
    background-color: #00c7b1!important;
    color: #ffffff !important;
    border: 1px solid #00c7b1!important;
  }
  &:focus {
    background-color: #00c7b1!important;
    color: #ffffff !important;
    border: 1px solid #00c7b1!important;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
  }
  @media (max-width: 839px) {
    width: 100%;
  }
}
.select-btn-dis {
  width: auto;
  opacity: 0.65;
  border-radius: 0.2rem;
  background-color: #6c757d;
  color: #ffffff;
  font-weight: 500;
  border: 1px solid #6c757d;
  padding: 0.25rem 0.5rem;
  line-height: 1.7;

  &:hover {
    background-color: #6c757d;
    color: #ffffff;
    border: 1px solid #6c757d;
  }
  @media (max-width: 839px) {
    width: 100%;
    text-align: center;
  }
}
.error {
  color: red;
}

.bounty_spinner_container{
  background-color: #00000060;
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 99;
}
.bounty_spinner {
  display: block;
  width: 40px;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 50%;
  position: absolute;
  display: none;
}
.bounty_double_bounce1,
.bounty_double_bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #00B189;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;

  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}
.bounty_double_bounce2 {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }
  
  @-webkit-keyframes sk-bounce {
    0%,
    100% {
      -webkit-transform: scale(0);
    }
    50% {
      -webkit-transform: scale(1);
    }
  }
  
  @keyframes sk-bounce {
    0%,
    100% {
      transform: scale(0);
      -webkit-transform: scale(0);
    }
    50% {
      transform: scale(1);
      -webkit-transform: scale(1);
    }
  }
  
  .bounty_spinner {
    display: block;
    width: 40px;
    height: 40px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: 50%;
    position: absolute;
    display: none;
  }
  .bounty_loading_title {
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: 50%;
    position: absolute;
    width: 300px;
    color: #FFFFFF;
    text-align: center;
    margin-top: 50px;
    font-size: 21px;
    font-family: "Poppins";
  }

  .modalts {
    width: 900px;
    // height: 70%;
    margin: 0 auto;
    max-width: 900px;
    @media (max-width: 839px) {
      top: 2%;
      //   height: 80%;
      width: 95%;
      max-width: 95%;
    }
  }

  .modalts {
    top: 5px;
    width: 85% !important;
    height: 70%;
    margin: 0 auto;
    max-width: 85% !important;
    
    @media (max-width: 839px) {
      height: 80%;
      width: 95% !important;
      max-width: 95% !important;
    }
  }
 

.modalts .modal-title {
  width: 100%;
}
.modalts .modal-body {
  height: 80vh;
}
.update-btn {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
.modalconfirmation {
  top: 10%;
  width: auto !important;
  height: auto;
  margin: 0 auto;
  max-width: auto !important;
  @media (max-width: 839px) {
    height: auto;
    width: auto !important;
    max-width: auto !important;
  }
}

.header {
  font-size: 36px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  padding-left: 40px;
  padding-top: 40px;
}

.dropzone {
  border : 2px dotted #DEE2E6;
  border-radius: 8px;
  padding: 8px;
  cursor: pointer;
}

.upload_container {
  display: grid;
  margin-top: 24px;
  grid-template-columns: auto 1fr;
  gap: 16px;
  align-items: center;
}

.btn {
  width: 175px;
  height: 50px;
  cursor: pointer;
}

.spinner {
  font-size: 40px;
  width: 1em;
  height: 1em;
  //margin: 100px auto;
  border-radius: 50%;
  box-shadow: inset 0 0 0 .1em hsl(166, 100%, 66%);
}

.spinner i {
  position: absolute;
  clip: rect(0, 1em, 1em, .5em);
  width: 1em;
  height: 1em;
  animation: spinner-circle-clipper 1s ease-in-out infinite;
}

@keyframes spinner-circle-clipper {
  0% {
      transform: rotate(0deg);
  }

  100% {
      transform: rotate(180deg);
  }
}

.spinner i:after {
  position: absolute;
  clip: rect(0, 1em, 1em, .5em);
  width: 1em;
  height: 1em;
  content: '';
  animation: spinner-circle 1s ease-in-out infinite;
  border-radius: 50%;
  box-shadow: inset 0 0 0 .1em #00B189;
}

@keyframes spinner-circle {
  0% {
      transform: rotate(-180deg);
  }

  100% {
      transform: rotate(180deg);
  }
}

.arrow {
  content: "";
  z-index: 1000;
  width: 10px;
  height: 10px;
  border: 2px solid #333;
  border-top: 0px solid #f2f2f2;
  border-right: 0px solid #f2f2f2;
  transform: rotate(135deg);
  transition: 0.5s;
  //pointer-events: none;
}
.arrow_active {
  transform: rotate(-45deg);
}
.bounty_urr {
  position: absolute;
  position: -webkit-sticky;
  top: 80px; /* required */
  z-index: 99999;
  right: 20px;
  filter: drop-shadow(0px 3.6137566566467285px 3.6137566566467285px #00000040);
  display: flex;
  height: 48px;
  background-color: #ffffff;
  padding: 12px 24px 12px 24px;
  border-radius: 4px;
  justify-content: space-between;
  div {
    cursor: pointer;
  }
}

.bounty_undo:active {
  .bounty_undo_svg {
    fill: #00b189;
  }
}
.bounty_redo:active {
  .bounty_redo_svg {
    fill: #00b189;
  }
}
.bounty_reset:active {
  .bounty_rest_svg {
    fill: #00b189;
  }
}