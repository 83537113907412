$ts_primary: #00b189;
$ts_secondary: #00b189;

@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700");

#bounty_main_controls_container {
  background-color: #fff;
  display: flex; // change
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  height: 60px;
  font-family: "Poppins";
  font-size: 12px;
  line-height: 18px;
  z-index: 100;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  grid-row: 1;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
#bounty_main_controls_container_mobile {
  background-color: #fff;
  display: flex; // change
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  height: 60px;
  font-family: "Poppins";
  font-size: 12px;
  line-height: 18px;
  z-index: 10;
  grid-row: 3;
  // position: absolute;
  // bottom: calc(env(safe-area-inset-bottom));
  width: 100%;
  //padding-bottom: env(safe-area-inset-bottom);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.fullscreen-enabled #bounty_main_controls_container {
  background-color: #fff;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: nowrap;
  height: 60px;
  font-family: "Poppins";
  font-size: 12px;
  line-height: 18px;
  z-index: 10;
  grid-row: 3;
  // position: absolute;
  // bottom: calc(env(safe-area-inset-bottom));
  width: 100%;
  //padding-bottom: env(safe-area-inset-bottom);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

#bounty_views_controls {
  display: grid;
  grid-template-columns: repeat(7, 50px);
  grid-template-rows: 60px;
  div {
    margin: 0 auto;
    display: grid;
    justify-items: center;
    align-items: center;
    cursor: pointer;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }
}
#bounty_views_controls_mobile {
  display: flex;
  // grid-template-columns: repeat(3, 50px);
  // grid-template-rows: 60px;
  height: 60px;
  .bounty_nav_icon {
    width: 100%;
    //border-left: 4px #00b18900 solid;
    height: 100%;
  }
  div {
    margin: 0 auto;
    display: grid;
    justify-items: center;
    align-items: center;
    cursor: pointer;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }
}
#bounty_movement_controls {
  display: grid;
  grid-template-columns: repeat(4, 50px);
  grid-template-rows: 60px;
  div {
    margin: 0 auto;
    display: grid;
    justify-items: center;
    align-items: center;
    cursor: pointer;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }
}

#bounty_render_controls {
  //flex: 1 1 50px;
  height: 60px;
  div {
    display: grid;
    justify-items: center;
    align-items: center;
    cursor: pointer;
  }
}
#bounty_render_controls_mobile {
  // flex: 1 1 50px;
  height: 60px;
  // max-width: 450px;
  // grid-row: 1;
  // width: 100%;
  div {
    display: grid;
    justify-items: center;
    align-items: center;
    cursor: pointer;
  }
}
#bounty_divider {
  content: "";
  width: 2px;
  height: 34px;
  background-color: #c8c8ca;
  display: grid;
  margin: 0px 30px;
}

.views_active {
  border-bottom: 4px  var(--ts_primary, $ts_primary) solid;
  font-weight: 700;
}
.views_active_mobile {
  border-left: 4px var(--ts_primary, $ts_primary) solid !important;
  font-weight: 700;
}
.bounty_initial_plan {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 85%;
  text-align: center;
  color: #898a8d;
  opacity: 0.6;
  position: absolute;
  top: 80px;
  left: 22%;
  z-index: 8;
}
.bounty_final_result {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 85%;
  text-align: center;
  color: #898a8d;
  opacity: 0.6;
  position: absolute;
  top: 80px;
  right: 22%;
  z-index: 8;
}
.bounty_canvas_container {
  display: contents;
  position: absolute;
}
.bounty_close_split {
  position: absolute;
  top: 75px;

  z-index: 8;
  cursor: pointer;
}
.bounty_nav_icon_full_screen {
  cursor: pointer;
}
.bounty_nav_icon_full_screen_mobile {
  position: fixed;
  top: 0;
  right: 0;
  @supports (-webkit-touch-callout: none) {
    display: none;
  }
  svg {
    .bounty_prime_color_svg {
      fill: #ffffff;
    }
  }
}

.zoomIn:active {
  border-bottom: 4px  var(--ts_primary, $ts_primary) solid;
}
.zoomOut:active {
  border-bottom: 4px  var(--ts_primary, $ts_primary) solid;
}

.bounty_tooltip_content {
  visibility: hidden;
  position: absolute;
  z-index: 1;
  top: 76px;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  height: auto;
  padding: 8px;
  width: max-content;
  height: max-content;
}

.bounty_tooltip_content:after {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  left: 50%;
  bottom: 75%;
  transform: translate(-50%, -50%) rotate(45deg);
  background-color: #ffffff;
}
.bounty_tools_icon {
  position: relative;
}
.bounty_nav_icon {
  width: 50px;
  &:hover {
    .bounty_tooltip_content {
      transition-delay: 1s;
      visibility: visible;
      font-weight: 500;
    }
  }
}

.bounty_dropdown_content {
  position: absolute;
  width: max-content;
  display: flex !important;
  z-index: 99;
  right: -55%;
  /* left: 10%; */
  top: 80px;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  height: auto;
  padding: 8px;
  align-items: flex-end;
  .bounty_nva_options_icon {
    margin: 0px 8px;
  }
  .bounty_nav_icon {
    margin: 0px 8px;
  }
}

.bounty_dropdown_content:after {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  right: 7%;
  bottom: 75%;
  z-index: -1;
  transform: translate(-50%, -50%) rotate(45deg);
  background-color: #ffffff;
}

.bounty_dropdown_container {
  position: absolute;
  background-color: #00000000;
  height: 100px;
  width: 50px;
  margin: 0 auto;
}

.bounty_icon_disabled {
  cursor: not-allowed !important;
  text-align: center;
  svg {
    opacity: 0.4;
  }
  div {
    opacity: 0.4;
  }
  &:hover {
    .bounty_tooltip_content {
      transition-delay: 0s;
      visibility: visible;
      font-weight: 500;
      opacity: 1 !important;
    }
  }
}

.bounty_views_render_container {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}
.bounty_views_render_container_mobile {
  width: 100vw;
  display: flex;
  //grid-template-rows: 1fr;
  overflow: auto;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: center;
}
.slider_wrap {
  overflow: hidden;
}
.slider_wrap:after {
  content: "";
  height: 100%;
  width: 10px;
  background: linear-gradient(
    -90deg,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}
